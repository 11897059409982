import HomePage from '@/pages/home/index/home';
import { TRENDING_CATEGORIES } from 'src/lib/consts/categories';
import { resetStore, updateM } from 'src/lib/tools/store/store';
import { initSSRStore } from 'src/lib/tools/store/store-bridge';
import uuid from 'src/lib/utils/uuid';
import { fetchWrapper } from 'src/modules/api';
import { updateTop10 } from 'src/modules/pages/top10/top10.init';
import { updateHeaders } from 'src/lib/utils/http-headers';
import { getCookie } from 'src/lib/utils/session';
import { AMAZON, DEFAULT_CID, PERSIST_SESSION_DATA, REQUEST_ID_PREFIX } from 'src/lib/consts/general';
import { doSearch } from 'src/lib/tools/search-feed';

export async function getServerSideProps(context) {
	const { req, res, query } = context;
	const { headers } = req;
	const { cookie } = headers;
	const { cid } = query;
	const ua = headers['user-agent'];

	const requestId = uuid(REQUEST_ID_PREFIX);
	const zStore = resetStore();
	initSSRStore(context, { requestId });
	updateM('set requestId', { requestId });

	let perssistSessionData = getCookie(PERSIST_SESSION_DATA, cookie); // !will not work on localhost, will work with real domains (next developer: change host file)
	perssistSessionData = perssistSessionData ? JSON.parse(perssistSessionData) : {};
	const { test } = perssistSessionData;

	const trendingCategoriesNames = TRENDING_CATEGORIES.map(c => c.name);

	const allPromises = await Promise.allSettled(
		trendingCategoriesNames.map(
			async categoryName =>
				await fetchWrapper(
					doSearch({
						options: {
							page: 1,
							maxYResults: 0,
							maxAmzResults: 5,
							cid: +(cid || DEFAULT_CID),
							q: categoryName,
							vendors: AMAZON,
							...test,
							sessionStart: !(test && test.testId),
							vendorProps: JSON.stringify({}),
						},
						ua,
						requestId,
					})
				)
		)
	);

	//const trendingCategories = [];
	const top10s = [];

	allPromises.forEach(({ value }, index) => {
		let offers = [];
		if (Array.isArray(value.productAds) && value.productAds.length > 0) {
			offers = value.productAds.map((product, position) => ({
				id: product.id,
				position,
				product,
			}));
		}

		if (!Array.isArray(value.relatedSearches)) {
			value.relatedSearches = [];
		}

		top10s.push({
			catId: '',
			catName: TRENDING_CATEGORIES[index].name,
			offers,
		});
	});

	const top10Data = {
		top10s: top10s,
		isSaveToCDN: false,
		related: [],
	};

	updateTop10(top10Data, null, requestId);
	updateHeaders(res);

	return {
		props: {
			zStore: zStore.getState(),
		},
	};
}

export default function Home() {
	return <HomePage />;
}
