import { top10UrlBest } from '@/utils/build-url';
import styles from './trending-categories.module.scss';
import { EVENTS } from '@/consts/events';
import { Link } from '@/components/link/link';
import { getEntry, updateM } from 'src/lib/tools/store/store';
import { trackOnNav } from 'src/lib/tools/tracking';
import { ProductItem } from '../product-item/product-item';

export default function TrendingCategories() {
	const top10Lists = getEntry('top10Lists');
	const failTop10Data = getEntry('failTop10Data');

	if (failTop10Data || !Array.isArray(top10Lists)) {
		return null;
	}

	const renderItems = top10 => {
		const { offers, catId } = top10;
		return (
			<div key={catId} className={styles.itemWrapper}>
				{offers.slice(0, 4).map((item, index) => {
					const { product = {} } = item;
					const { title } = product;
					return <ProductItem product={item.product} index={index} key={title + product.id} />;
				})}
			</div>
		);
	};

	const renderTrendingCategory = (top10, i) => {
		const { catName } = top10;
		return (
			<section key={catName} className={styles.category}>
				<div className={styles.categoryLine}>
					<span>The 10 Best {catName}</span>
					<Link hrefCB={() => top10UrlBest(catName, { q: catName })}>
						<span
							onClick={() => {
								trackOnNav(EVENTS.CLICK.POP_CAT, { name: catName, position: i, section: 'Home Trending' });
								updateM('Header Loader', { isHeaderLoader: true });
							}}>
							View All
						</span>
					</Link>
				</div>
				<div className={styles.categoryItems}>{renderItems(top10)}</div>
			</section>
		);
	};

	return (
		<div className={styles.wrapper}>
			<section className={styles.section}>
				<div className={styles.content}>{top10Lists.map((top10, i) => renderTrendingCategory(top10, i))}</div>
			</section>
		</div>
	);
}
