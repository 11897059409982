import MainLogo from '@/components/main-logo/main-logo';
import SearchForm from '@/components/search-form/search-form';
import styles from './hero.module.scss';

export default function Hero() {
	return (
		<div className={styles.homeHero}>
			<div className={styles.homeHeroLogo}>
				<MainLogo />
			</div>
			<div className={styles.homeHeroContent}>
				<div className={styles.homeLeftImg}>
					{/* <Image alt={''} src={homeLeftImg} layout={'fixed'} height={230} width={308} /> */}
					<img alt={''} src={'/bestreviews.zone/home-left-img.png'} height={230} width={308} />
				</div>
				<h1>
					Ranking The <span>Best</span> Products Worth Buything
				</h1>
				<h3>We examine over 20M products and find the best ones</h3>
				<div className={styles.searchFormWrapper}>
					<SearchForm placeholder={'Show me the best!'} customStyles={{ bar: { backgroundColor: 'white' } }} />
				</div>
				<div className={styles.homeRightImg}>
					{/* <Image alt="" src={homeRightImg} layout={'fixed'} height={216} width={315} /> */}
					<img alt={''} src={'/bestreviews.zone/home-right-img.png'} height={216} width={315} />
				</div>
			</div>
		</div>
	);
}
