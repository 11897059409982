export const parsePrice = (price = '', symbol = '', digits = 2) => {
	if (!price) return null;
	const floatPrice = toFixedNoRounding(parseFloat(price), digits);
	return `${symbol}${floatPrice.toString()}`;
};

/**
 * @param {*} price 
 * @returns 
 */
export const parseFloatingDigits = (price = '') => {
	if (!price) return null;
	const floatPrice = toFixedNoRounding(parseFloat(price), 2);
	const priceArr = floatPrice.split('.');
	return `${priceArr[1] || ''}`;
};

/**
 * Will calc current price of product
 * @param {*} product 
 * @returns 
 */
export const parseProductPrice = product => {
	if (!product) return null;
	const { currentPrice, currencySymbol } = product;
	return parsePrice(currentPrice, currencySymbol);
};

/**
 * Will calculate price with shipping
 * @param {*} product 
 * @returns 
 */
export const parseTotalPrice = product => {
	if (!product) return null;
	const { isFreeShipping, currentPrice, currencySymbol, shippingPrice } = product;
	let shipping = 0;
	if (!isFreeShipping && shippingPrice > 0) {
		shipping = shippingPrice;
	}
	return parsePrice(currentPrice + shipping, currencySymbol);
};

/**
 * The idea is to convert the number into string (via toString()) and use the RegExp to truncate the extra zeros (without rounding).
 * Next, we find out the position of decimal dot (if any), and pad up the zeros for exactly n decimal places.
 * We compute the number of zeros we need and if it is positive,
 * we use the String.prototype.repeat() to insert additional zeros at the end.
 * took from: https://helloacm.com/javascripts-tofixed-implementation-without-rounding/
 * @param {*} num
 * @param {*} n
 * @returns
 */
const toFixedNoRounding = (num, digits) => {
	let numStringWithDot = num.toString().match(new RegExp('^-?\\d+(?:\\.\\d{0,' + digits + '})?', 'g'))[0];
	if (digits === 0) numStringWithDot = numStringWithDot.replace('.', ''); // in case of no digits needed after the point just remove the dot
	const dotPosition = numStringWithDot.indexOf('.');
	if (dotPosition === -1) {
		// integer, insert decimal dot and pad up zeros
		return numStringWithDot + (digits > 0 ? '.' : '') + '0'.repeat(digits);
	}
	const b = digits - (numStringWithDot.length - dotPosition) + 1;
	return b > 0 ? numStringWithDot + '0'.repeat(b) : numStringWithDot;
};
