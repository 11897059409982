import styles from './home.module.scss';
import Hero from '../hero/hero';
import CategoriesBoxes from '@/components/categories-boxes/categories-boxes';
import TrendingCategories from '@/components/trending-categories/trending-categories';
import Newsletters from '@/components/newsletters/newsletters';
import Footer from '@/components/footer/footer';

export default function HomePage() {
	return (
		<div className={styles.homePage}>
			<Hero />
			<section>
				<CategoriesBoxes />
			</section>
			<section>
				<TrendingCategories />
			</section>
			<Newsletters />
			<Footer />
		</div>
	);
}
