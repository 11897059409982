import { YAHOO_PRODUCT } from 'src/lib/consts/general';
import { getEntry } from 'src/lib/tools/store/store';
import { parseProductPrice } from 'src/lib/utils/price';
import { ProductCTAButton } from '../product-cta-button/product-cta-button';
import styles from './product-item.module.scss';

export const ProductItem = props => {
	const { product, index, customStyles = {} } = props;
	const { title, imageUrl, vendorId } = product;
	const isCompliant = getEntry('isCompliant');
	const isYahooItem = vendorId === YAHOO_PRODUCT;

	if (isCompliant && isYahooItem) {
		return (
			<div className={`${styles.item} ${styles.compliantItem}`} style={customStyles} key={title}>
				<span className={styles.co}>Ad</span>
				<div className={styles.imgWrapper}>
					<ProductCTAButton eventSection={'Image'} product={product} position={index} className={styles.imgClickWrapper}>
						<img src={imageUrl} alt={title} />
					</ProductCTAButton>
				</div>
				<div className={styles.titleWrapper}>
					<ProductCTAButton eventSection={'Title'} product={product} className={styles.titleCTA} position={index}>
						<span dangerouslySetInnerHTML={{ __html: title }}></span>
					</ProductCTAButton>
				</div>
				<div className={styles.ctaWrapper}>{parseProductPrice(product)}</div>
			</div>
		);
	}

	return (
		<div className={`${styles.item} ${isYahooItem ? styles.yItem : ''}`} style={customStyles} key={title}>
			{isYahooItem ? <span className={styles.yPrice}>{parseProductPrice(product)}</span> : null}
			<div className={styles.imgWrapper}>
				<ProductCTAButton eventSection={'Image'} product={product} position={index} className={styles.imgClickWrapper}>
					<img src={imageUrl} alt={title} />
				</ProductCTAButton>
			</div>
			<div className={styles.titleWrapper}>
				<ProductCTAButton eventSection={'Title'} product={product} className={`${styles.titleCTA} ${styles.blackTitle}`} position={index}>
					<span dangerouslySetInnerHTML={{ __html: title }}></span>
				</ProductCTAButton>
			</div>
			<div className={styles.ctaWrapper}>
				<ProductCTAButton eventSection={'Home'} product={product} className={styles.primaryBtn} position={index}>
					VIEW DEAL
				</ProductCTAButton>
			</div>
		</div>
	);
};
